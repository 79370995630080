//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      userInput: "",
      searchText: "",
    };
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    setFocus() {
      this.$refs.search.focus();
    },
    onEnter() {
      this.userInput = this.searchText;
      // eslint-disable-next-line
      console.log("The typed in value: " + this.userInput);
      this.$router.push({
        path: `/searchPage/${this.userInput}`,
      });
    },
  },
};
