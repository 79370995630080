import consola from "consola";

export default (
  { graphQLErrors, networkError, operation, forward },
  nuxtContext
) => {
  consola.error("Global error handler");
  consola.error("graphQLErrors: ", graphQLErrors);
  consola.error("networkError:", networkError);
  consola.error("operation:", operation);
  consola.error("forward:", forward);
};
