export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const HomeWavesStatic = () => import('../../components/home/waves-static.vue' /* webpackChunkName: "components/home-waves-static" */).then(c => wrapFunctional(c.default || c))
export const HomeWaves2 = () => import('../../components/home/waves2.vue' /* webpackChunkName: "components/home-waves2" */).then(c => wrapFunctional(c.default || c))
export const PortfoliosPortfolioCard = () => import('../../components/portfolios/PortfolioCard.vue' /* webpackChunkName: "components/portfolios-portfolio-card" */).then(c => wrapFunctional(c.default || c))
export const PortfoliosSimilarPortfolios = () => import('../../components/portfolios/SimilarPortfolios.vue' /* webpackChunkName: "components/portfolios-similar-portfolios" */).then(c => wrapFunctional(c.default || c))
export const Posts = () => import('../../components/posts/Posts.vue' /* webpackChunkName: "components/posts" */).then(c => wrapFunctional(c.default || c))
export const PostsSimilarPosts = () => import('../../components/posts/SimilarPosts.vue' /* webpackChunkName: "components/posts-similar-posts" */).then(c => wrapFunctional(c.default || c))
export const TeamMember = () => import('../../components/team_members/TeamMember.vue' /* webpackChunkName: "components/team-member" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
