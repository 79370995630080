import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c024e1d2 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _e4af78f0 = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _7cd64d18 = () => interopDefault(import('../pages/connect.vue' /* webpackChunkName: "pages/connect" */))
const _579fac75 = () => interopDefault(import('../pages/people.vue' /* webpackChunkName: "pages/people" */))
const _91aae912 = () => interopDefault(import('../pages/work.vue' /* webpackChunkName: "pages/work" */))
const _1b25663c = () => interopDefault(import('../pages/blogPage/_id.vue' /* webpackChunkName: "pages/blogPage/_id" */))
const _0afec1b9 = () => interopDefault(import('../pages/search/_id.vue' /* webpackChunkName: "pages/search/_id" */))
const _3ec7945e = () => interopDefault(import('../pages/workPage/_id.vue' /* webpackChunkName: "pages/workPage/_id" */))
const _7e1fbe70 = () => interopDefault(import('../pages/searchPage/_slug/_.vue' /* webpackChunkName: "pages/searchPage/_slug/_" */))
const _80b52c48 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _c024e1d2,
    name: "about"
  }, {
    path: "/blog",
    component: _e4af78f0,
    name: "blog"
  }, {
    path: "/connect",
    component: _7cd64d18,
    name: "connect"
  }, {
    path: "/people",
    component: _579fac75,
    name: "people"
  }, {
    path: "/work",
    component: _91aae912,
    name: "work"
  }, {
    path: "/blogPage/:id?",
    component: _1b25663c,
    name: "blogPage-id"
  }, {
    path: "/search/:id?",
    component: _0afec1b9,
    name: "search-id"
  }, {
    path: "/workPage/:id?",
    component: _3ec7945e,
    name: "workPage-id"
  }, {
    path: "/searchPage/:slug?/*",
    component: _7e1fbe70,
    name: "searchPage-slug-all"
  }, {
    path: "/",
    component: _80b52c48,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
