// import {
//   InMemoryCache,
//   IntrospectionFragmentMatcher,
// } from "apollo-cache-inmemory";
import { InMemoryCache } from "apollo-cache-inmemory";
// import schema from "./fragments.json";
// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData: schema,
// });

export default ({ req, app }) => {
  const headersVal = {
    "Accept-Language": "en-us",
  };
  return {
    httpEndpoint: "https://wp.2wav.com/graphql",
    // optional
    // override HTTP endpoint in browser only
    // browserHttpEndpoint: "/graphql",
    // optional
    // See https://www.apollographql.com/docs/link/links/http.html#options
    httpLinkOptions: {
      headers: headersVal,
      // credentials: "same-origin",
      // credentials: "include",
    },
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: 'ws://localhost:4000', // optional
    // LocalStorage token
    tokenName: "apollo-token", // optional
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false, // Optional
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false, // Optional
    // cache: new InMemoryCache({ fragmentMatcher }),
    cache: new InMemoryCache(),
  };
};
