//
//
//
//
//
//


export default {
  methods: {
    track() {
      this.$gtag.event("page_view", {
        "page_path": `${this.$route.fullPath}`,
        "send_to": "G-C1WT96B64B"
      })
      this.$gtag.screenview({
        "app_name": '2WAV',
        "screen_name": `${this.$route.fullPath}`,
      })
    }
  }
}
