//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GetMainMenu from "~/apollo/queries/getMainMenu";
import SearchInput from "~/components/SearchInput";

export default {
  components: {
    SearchInput,
  },
  data() {
    return {
      activePage: "activeOrange",
      indexPage: "indexDisappear",
      showSearch: false,
      showDrop: false,
    };
  },
  apollo: {
    menu: {
      query: GetMainMenu,
      update(data) {
        return data.menu?.menuItems?.edges;
      },
    },
  },
  mounted() {
    this.track();
    console.log(`GTAG EVENT ${this.$route.fullPath}`);
  },
  methods: {
    track() {
      this.$gtag.event("page_view", {
        "page_path": `${this.$route.fullPath}`,
        "send_to": "G-C1WT96B64B"
      });
      this.$gtag.screenview({
        "app_name": '2WAV',
        "screen_name": `${this.$route.fullPath}`,
      })
    },
    search() {
      if (this.showSearch === false) {
        this.showSearch = true;
      } else {
        this.showSearch = false;
      }
    },
    getClass(x) {
      const activeClass = this.activePage;
      if (this.$route.fullPath.includes(x.toLowerCase())) {
        return activeClass;
      }
    },
    getWaveClass() {
      const newClass = this.indexPage;
      if (
        !this.$route.fullPath.includes("about") &&
        !this.$route.fullPath.includes("work") &&
        !this.$route.fullPath.includes("connect") &&
        !this.$route.fullPath.includes("people") &&
        !this.$route.fullPath.includes("blog") &&
        !this.$route.fullPath.includes("search")
      ) {
        return newClass;
      }
    },
    showMenu() {
      if (this.showDrop === false) {
        this.showDrop = true;
      } else {
        this.showDrop = false;
      }
    },
  },
};
